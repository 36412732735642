import {mapMutations, mapActions, mapGetters} from "vuex";

export default {
  name: "page-not-found",
  components: {},
  data() {
    return{

    }
  },
  mounted() {
    document.title = `${this.$t('error.pageNotFound')} | karbosnab`
  },
  created() {
    this.$router.push({ name: 'page-not-found' })
  },
  computed: {},
  methods: {
    goHomePage() {
      this.$router.push({name: 'home'})
    }
  }
}
